<template>
    <main>
        <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
        <page-header-compact>
            Ordenes de Medicamentos
            <template v-slot:button>
                <router-link :to="{ name: 'historias.perfil.usuario', params: { idUsuario: usuario.id } }"
                    class="btn btn-success btn-sm shadow-sm mx-2">
                        Regresar al perfil del paciente <i class="fas fa-history fa-fw"></i>
                </router-link>
            </template>
        </page-header-compact>
        <div class="container-xl px-4">
            <div class="card mt-2">
                <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                      <ordenMedicamentoComponent :usuario="usuario" :idHistoria="idHistoria"></ordenMedicamentoComponent>
                </div>
            </div>
        </div>
    </main>
</template>
<script>

import modalInformacionUsuario from "../../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../../base/informacionUsuario/cardInformacionUsuario";
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import Toast from '../../../../components/common/utilities/toast';
import ordenMedicamentoComponent from './ordenMedicamentoComponent';
import historiaClinicaService from '../../../../services/historiaClinicaService';

export default {
    components: {modalInformacionUsuario,cardInformacionUsuario,PageHeaderCompact,ordenMedicamentoComponent},
    data(){
        return {
            usuario: {},
            idHistoria:''
        }
    },
    methods: {
        async cargarUsuario(id) {
            const response = await historiaClinicaService.show(id);
            this.usuario = response.data.usuario;
        },
    },
    async created(){
        try {
            this.LoaderSpinnerShow();
            this.idHistoria = this.$route.params.id_historia;
            await this.cargarUsuario(this.idHistoria);
            
            this.LoaderSpinnerHide();
        } catch (error) {
            console.error(error);
            this.LoaderSpinnerHide();
            Toast.fire({
                icon: 'error',
                title: 'Ocurrio un error al procesar la solicitud'
            });
        }
        
    }
}
</script>